import { CasePurpose, CompanyType, TaxType } from "@prisma/client";
import { type QuestionOption } from "~/modules/onboarding/interfaces";

// CASE
export const casePurposeOptions: QuestionOption[] = [
  {
    label: "Rembourser un compte courant d’associé",
    value: CasePurpose.repay_current_account,
  },
  {
    label: "Apport de compte courant d’associé",
    value: CasePurpose.associated_current_account_contribution,
  },
  {
    label: "Financer une acquisition / travaux / croissance",
    value: CasePurpose.finance_acquisition,
  },
  // {
  //   label: "Financer une vente à soi-même",
  //   value: CasePurpose.finance_self_sale,
  // },
];

export const caseTypeOptions: QuestionOption[] = [
  {
    label: "Personne physique",
    value: "solo",
  },
  {
    label: "Société patrimoniale",
    value: CompanyType.patrimony,
  },
  {
    label: "Société d’exploitation commerciale",
    value: CompanyType.commercial,
  },
];

// COMPANY
export const companyTypeOptions: QuestionOption[] = [
  {
    label: "IS",
    value: TaxType.is,
  },
  {
    label: "IR",
    value: TaxType.ir,
  },
];
