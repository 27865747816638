import { AssetUsageType } from "@prisma/client";
import { getValuesFromRepeatableAnswer } from "src/utils/answers";
import { z } from "zod";
import { schemaAge } from "~/modules/common/schemas";
import {
  assetTypeOptions,
  booleanOptions,
} from "~/modules/common/static/common/formSelectOption";
import { COUNTRIES_FR_AND_DOMTOM } from "~/modules/common/utils/countries";
import {
  assetCompanyTypeOptions,
  assetLoanIsHypotecOptions,
  assetOwnershipTypeOptions,
  assetShareholdersTypeOptions,
} from "../../../common/static/standard/formSelectOption";
import {
  CustomerCaseType,
  QuestionInlineSlug,
  QuestionSimpleSlug,
} from "../../enums";
import { type Question, type QuestionOption } from "../../interfaces";
import { schemaSurface } from "../../schemas";
import { getAssetUsageTypeOption } from "../../utils/questionOptions";

export const questionsSenior: Question[] = [
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.seniorLoansAccept,
    question: "Êtes-vous prêt à payer une mensualité pour ce financement?",
    type: "btn",
    placeholder: "",
    options: booleanOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.FinancingRequestedAmount,
    question: "De quel montant avez-vous besoin (€) ?",
    type: "currency-positive",
    placeholder: "200 000",
    unit: "€",
    label: "Montant",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.FinancingUsageDescription,
    question: "Décrivez-nous votre projet",
    type: "textarea",
    placeholder: "Confort à la retraite",
    label: "Votre projet :",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CaseType,
    question: "Comment souhaitez-vous emprunter ?",
    type: "btn",
    placeholder: "",
    options: [
      {
        label: "Seul",
        value: CustomerCaseType.Solo,
      },
      {
        label: "En couple",
        value: CustomerCaseType.Duo,
      },
    ],
  },

  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetAddress,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel bien souhaitez vous mettre en garantie ?"
        : "Où se situe votre bien immobilier ?";
    },
    infoBox: "Si votre projet est d’acheter un bien, saisissez-le ici.",
    type: "address",
    countryList: COUNTRIES_FR_AND_DOMTOM,
    placeholder: "",
    label: "Adresse du bien :",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetValue,
    question: "Quelle est sa valeur ?",
    type: "currency-positive",
    placeholder: "600 000",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetSurface,
    question: "Quelle est sa surface ?",
    type: "number",
    placeholder: "100",
    unit: "m²",
    customSchema: schemaSurface,
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data > 500) {
        return "S’agit-il bien de la surface de votre bien ?";
      }
      return null;
    },
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AssetLoans,
    question: "Vos crédits sur le bien immobilier",
    description: "",
    itemName: "Crédit",
    questionsInline: [
      {
        slug: QuestionInlineSlug.AssetLoanMonthlyPaymentAmount,
        question: "Mensualité",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
      },
      {
        slug: QuestionInlineSlug.AssetLoanEndDate,
        question: "Date de fin",
        type: "date",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.AssetLoanRate,
        question: "Taux",
        // TODO: Change to percentage type, but data saver must be updated accordingly
        type: "number",
        placeholder: "1.5",
        unit: "%",
      },
      {
        slug: QuestionInlineSlug.AssetLoanIsHypotec,
        question: "Type de garantie",
        type: "select",
        placeholder: "",
        options: assetLoanIsHypotecOptions,
      },
    ],
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetType,
    question: "Quel est la typologie de ce bien ?",
    type: "btn",
    placeholder: "",
    options: assetTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetUsageType,
    question: "Quel est l'usage de ce bien ?",
    type: "btn",
    placeholder: "",
    options: (answers) => {
      const options: QuestionOption[] = [];
      const assetUsageTypes = answers.filter(
        (answer) => answer.questionSlug === QuestionSimpleSlug.AssetUsageType,
      );

      if (
        !assetUsageTypes.find(
          (answer) => answer.value === AssetUsageType.main_residence,
        )
      ) {
        options.push(getAssetUsageTypeOption(AssetUsageType.main_residence));
      }
      options.push(getAssetUsageTypeOption(AssetUsageType.secondary_residence));
      options.push(getAssetUsageTypeOption(AssetUsageType.rental));

      return options;
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetRentAnnualAmount,
    question: "Quel revenu locatif en retirez-vous annuellement ?",
    infoBox:
      "Si votre projet est d’acheter un bien locatif, renseignez le loyer futur ici",
    type: "currency-positive",
    placeholder: "15 000",
    unit: "€",
    label: "Montant",
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data < 1000) {
        return "S’agit-il du revenu que vous en retirez annuellement ?";
      }

      return null;
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetOwnershipType,
    question: "Comment est-il détenu ?",
    type: "btn",
    placeholder: "",
    options: assetOwnershipTypeOptions,
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AssetJointOwnerInfo,
    question: "Vos indivisaires",
    description: "Les personnes avec qui vous détenez ce bien",
    itemName: "Indivisaire",
    questionsInline: [
      {
        slug: QuestionInlineSlug.AssetJointOwnerFirstname,
        question: "Prénom",
        type: "text",
        placeholder: "Jean",
      },
      {
        slug: QuestionInlineSlug.AssetJointOwnerLastname,
        question: "Nom",
        type: "text",
        placeholder: "Dupont",
      },
      {
        slug: QuestionInlineSlug.AssetJointOwnerSharePercentage,
        question: "Parts",
        type: "number",
        placeholder: "15",
        unit: "%",
      },
    ],
    initialValues: (answers) => {
      const values = getValuesFromRepeatableAnswer(answers, {
        slug: QuestionSimpleSlug.AssetJointOwnerInfo,
        categoryIndex: 1,
      });
      return values;
    },
    refineSchema: {
      condition: (data) => {
        const sumSharePercentage = data.reduce((acc, item) => {
          const parsedValue = z
            .number()
            .safeParse(item[QuestionInlineSlug.AssetJointOwnerSharePercentage]);
          if (!parsedValue.success) {
            return acc;
          }
          return acc + parsedValue.data;
        }, 0);
        return sumSharePercentage <= 100;
      },
      params: {
        message:
          "La somme des parts de vos indivisaires doit être inférieur ou égale à 100",
        path: ["sumOfPercentage"],
      },
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetCompanyType,
    question: "Type de société ?",
    type: "btn",
    placeholder: "",
    options: assetCompanyTypeOptions,
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AssetCompanyShareholderInfo,
    question: "Vos actionnaires",
    description: "",
    itemName: "Actionnaire",
    questionsInline: [
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderFirstname,
        question: "Prénom",
        type: "text",
        placeholder: "Jean",
      },
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderLastname,
        question: "Nom",
        type: "text",
        placeholder: "Dupont",
      },
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderType,
        question: "Type",
        type: "select",
        placeholder: "",
        options: assetShareholdersTypeOptions,
      },
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderSharePercentage,
        question: "Parts",
        type: "number",
        placeholder: "15",
        unit: "%",
      },
    ],
    refineSchema: {
      condition: (data) => {
        const sumSharePercentage = data.reduce((acc, item) => {
          const parsedValue = z
            .number()
            .safeParse(
              item[QuestionInlineSlug.AssetCompanyShareholderSharePercentage],
            );
          if (!parsedValue.success) {
            return acc;
          }
          return acc + parsedValue.data;
        }, 0);
        return sumSharePercentage <= 100;
      },
      params: {
        message:
          "La somme des parts de vos actionnaires doit être inférieur ou égale à 100",
        path: ["sumOfPercentage"],
      },
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetAddNew,
    question: "Avez-vous d’autres biens immobiliers?",
    type: "btn",
    placeholder: "",
    options: booleanOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerAge,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel est votre âge ?"
        : "Quel est son âge ?";
    },
    type: "number",
    placeholder: "70",
    unit: "ans",
    customSchema: schemaAge,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerAddress,
    question: "Où habitez-vous ?",
    type: "address",
    placeholder: "",
    unit: "",
    countryList: COUNTRIES_FR_AND_DOMTOM,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.HousingRentMonthlyAmount,
    question: "Quel est le montant du loyer mensuel ?",
    type: "currency-positive",
    placeholder: "0,00",
    unit: "€",
    label: "Montant",
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.HousingLoans,
    question: "Vos crédits sur le bien immobilier",
    description: "",
    itemName: "Crédit",
    questionsInline: [
      {
        question: "Mensualité",
        slug: QuestionInlineSlug.HousingLoanMonthlyPaymentAmount,
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
      },
      {
        slug: QuestionInlineSlug.HousingLoanEndDate,
        question: "Date de fin",
        type: "date",
        placeholder: "",
        unit: "",
      },
      {
        slug: QuestionInlineSlug.HousingLoanRate,
        question: "Taux",
        // TODO: Change to percentage type, but data saver must be updated accordingly
        type: "number",
        placeholder: "1.5",
        unit: "%",
      },
    ],
  },
];
