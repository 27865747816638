import {
  AssetOwnershipType,
  CustomerEmployeeType,
  HousingStatus,
  JobType,
  MaritalStatus,
} from "@prisma/client";
import { QuestionSimpleSlug } from "../../enums";
import { type Branch, type BranchSlug } from "../../interfaces";
import {
  getCustomerJobEmployeeType,
  getCustomerJobType,
  hasCoCustomer,
  hasMainResidenceInAssets,
  isCompany,
  isMainHomeInfoFilled,
} from "../../utils/answersConditionsManager";
import { questions } from "./questions";

export const branches: Branch[] = [
  {
    slug: "case-type",
    questions: [
      // TODO REFACTO use dictionary or map structures instead of using "find"
      questions.find((q) => q.slug === QuestionSimpleSlug.CaseType)!,
    ],
    handleNext: (answers, navigation) => {
      if (isCompany(answers)) {
        return {
          ...navigation,
          redirect: "/onboarding/patrimonial",
        };
      }

      return {
        ...navigation,
        branchSlug: "case-info",
      };
    },
    // Cannot go back, this is the first branch
    handleBack: (answers, navigation) => navigation,
  },
  {
    slug: "case-info",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.CasePurpose)!,
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.FinancingRequestedAmount,
      )!,
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.FinancingUsageDescription,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-start",
        categoryIteration: 1,
        categorySlug: "real-estate-patrimony",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "case-type",
      };
    },
  },
  {
    slug: "asset-creation-start",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetAddress)!,
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetValue)!,
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetSurface)!,
    ],
    handleNext: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-loans",
    }),
    handleBack: (answers, navigation) => {
      if (navigation.categoryIteration > 1) {
        return {
          ...navigation,
          branchSlug: "asset-creation-add-new",
          categoryIteration: navigation.categoryIteration - 1,
        };
      } else {
        return {
          ...navigation,
          branchSlug: "case-info",
          categorySlug: "case",
        };
      }
    },
  },
  {
    slug: "asset-creation-loans",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetLoans)!,
    ],
    handleNext: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-asset-type",
    }),
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-start",
    }),
  },
  {
    slug: "asset-creation-asset-type",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetType)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-usage-type",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-loans",
    }),
  },
  {
    slug: "asset-creation-usage-type",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetUsageType)!,
    ],
    handleNext: (answers, navigation) => {
      const assetUsageType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetUsageType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      return {
        ...navigation,
        branchSlug:
          assetUsageType === "rental"
            ? "asset-creation-rent"
            : "asset-creation-owner",
      };
    },
    handleBack: (answers, navigation) => ({
      ...navigation,
      branchSlug: "asset-creation-asset-type",
    }),
  },
  {
    slug: "asset-creation-rent",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.AssetRentAnnualAmount,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-usage-type",
      };
    },
  },
  {
    slug: "asset-creation-owner",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetOwnershipType)!,
    ],
    handleNext: (answers, navigation) => {
      const assetOwnerShipType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetOwnershipType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;
      if (assetOwnerShipType === AssetOwnershipType.company) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-company-type",
        };
      }
      if (assetOwnerShipType === AssetOwnershipType.indivision) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-undivided",
        };
      }
      if (assetOwnerShipType === AssetOwnershipType.propre) {
        return {
          ...navigation,
          branchSlug: "asset-creation-add-new",
        };
      }
      throw new Error("Unknown ownership type");
    },
    handleBack: (answers, navigation) => {
      const assetUsageType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetUsageType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;
      return {
        ...navigation,
        branchSlug:
          assetUsageType === "rental"
            ? "asset-creation-rent"
            : "asset-creation-usage-type",
      };
    },
  },
  {
    slug: "asset-creation-owner-undivided",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetJointOwnerInfo)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        branchIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner",
      };
    },
  },
  {
    slug: "asset-creation-owner-company-type",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetCompanyType)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner-company-shareholders",
        branchIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner",
      };
    },
  },
  {
    slug: "asset-creation-owner-company-shareholders",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.AssetCompanyShareholderInfo,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-add-new",
        branchIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "asset-creation-owner-company-type",
      };
    },
  },
  {
    slug: "asset-creation-add-new",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AssetAddNew)!,
    ],
    handleNext: (answers, navigation) => {
      const shouldAddNewAsset = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetAddNew &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (shouldAddNewAsset === "true") {
        return {
          ...navigation,
          branchSlug: "asset-creation-start",
          categoryIteration: navigation.categoryIteration + 1,
        };
      } else {
        return {
          ...navigation,
          branchSlug: "customer-info-common",
          categorySlug: "situation",
          categoryIteration: 1,
        };
      }
    },
    handleBack: (answers, navigation) => {
      const ownerShipType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AssetOwnershipType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (ownerShipType === AssetOwnershipType.propre) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner",
        };
      }
      if (ownerShipType === AssetOwnershipType.indivision) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-undivided",
        };
      }
      if (ownerShipType === AssetOwnershipType.company) {
        return {
          ...navigation,
          branchSlug: "asset-creation-owner-company-shareholders",
        };
      }

      throw new Error("Unknown ownership type");
    },
  },
  {
    slug: "customer-info-common",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.CustomerAge)!,
    ],
    handleNext: (answers, navigation) => {
      const isMainCustomer = navigation.categoryIteration === 1;

      if (isMainCustomer) {
        return {
          ...navigation,
          branchSlug: "customer-info-general",
        };
      } else {
        return {
          ...navigation,
          branchSlug: "customer-info-job",
        };
      }
    },
    handleBack: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 2) {
        // TODO factorize this logic
        let newBranchSlug: BranchSlug;
        const mainCustomerJobType = getCustomerJobType(1, answers);

        if (mainCustomerJobType === JobType.employee) {
          const coCustomerJobEmployeeType = getCustomerJobEmployeeType(
            1,
            answers,
          );

          if (coCustomerJobEmployeeType === CustomerEmployeeType.CDD) {
            newBranchSlug = "customer-info-job-employee";
          } else {
            newBranchSlug = "customer-info-job-permanent";
          }
        } else {
          newBranchSlug =
            mainCustomerJobType === JobType.self_employed
              ? "customer-info-job-other"
              : "customer-info-job";
        }

        return {
          ...navigation,
          branchSlug: newBranchSlug,
          categoryIteration: 1,
        };
      } else {
        const previousCategoryIterationsCount = answers.filter(
          (a) => a.questionSlug === QuestionSimpleSlug.AssetAddNew,
        ).length;

        return {
          ...navigation,
          branchSlug: "asset-creation-add-new",
          categoryIteration: previousCategoryIterationsCount,
          categorySlug: "real-estate-patrimony",
        };
      }
    },
  },
  {
    slug: "customer-info-general",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.CustomerChildrenCount,
      )!,
    ],
    handleNext: (answers, navigation) => {
      if (hasMainResidenceInAssets(answers)) {
        return {
          ...navigation,
          branchSlug: "customer-info-marital-status",
        };
      } else {
        return {
          ...navigation,
          branchSlug: "customer-info-address",
        };
      }
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-common",
      };
    },
  },
  {
    slug: "customer-info-address",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.CustomerAddress)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-marital-status",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-general",
      };
    },
  },
  {
    slug: "customer-info-marital-status",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.CustomerMaritalStatus,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const customerMaritalStatus = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.CustomerMaritalStatus &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      switch (customerMaritalStatus) {
        case MaritalStatus.married:
          return {
            ...navigation,
            branchSlug: "customer-info-married",
          };
        case MaritalStatus.single:
        case MaritalStatus.pacs:
        case MaritalStatus.cohabitation:
          return {
            ...navigation,
            branchSlug: "customer-info-job",
          };
      }

      throw new Error("Unknown marital status");
    },
    handleBack: (answers, navigation) => {
      if (hasMainResidenceInAssets(answers)) {
        return {
          ...navigation,
          branchSlug: "customer-info-general",
        };
      }
      return {
        ...navigation,
        branchSlug: "customer-info-address",
      };
    },
  },
  {
    slug: "customer-info-married",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.CustomerMarriageType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-job",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-marital-status",
      };
    },
  },
  {
    slug: "customer-info-job",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.CustomerJobType)!,
    ],
    handleNext: (answers, navigation) => {
      const jobType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.CustomerJobType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (jobType === JobType.employee) {
        return {
          ...navigation,
          branchSlug: "customer-info-job-employee",
        };
      } else if (jobType === JobType.self_employed) {
        return {
          ...navigation,
          branchSlug: "customer-info-job-other",
        };
      } else if (jobType === JobType.retired) {
        if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
          return {
            ...navigation,
            branchSlug: "customer-info-common",
            categoryIteration: 2,
          };
        } else {
          // TODO factorize this logic
          const mainCustomerJobType = getCustomerJobType(1, answers);
          const newBranchSlug: BranchSlug =
            mainCustomerJobType === JobType.employee
              ? "revenues-employee"
              : mainCustomerJobType === JobType.self_employed
                ? "revenues-last-year"
                : mainCustomerJobType === JobType.retired
                  ? "revenues-pension"
                  : "revenues-additional";

          return {
            ...navigation,
            branchSlug: newBranchSlug,
            categorySlug: "revenues",
            categoryIteration: 1,
          };
        }
      } else if (jobType === JobType.unemployed) {
        if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
          return {
            ...navigation,
            branchSlug: "customer-info-common",
            categoryIteration: 2,
          };
        } else {
          // TODO factorize this logic
          const mainCustomerJobType = getCustomerJobType(1, answers);
          const newBranchSlug: BranchSlug =
            mainCustomerJobType === JobType.employee
              ? "revenues-employee"
              : mainCustomerJobType === JobType.self_employed
                ? "revenues-last-year"
                : mainCustomerJobType === JobType.retired
                  ? "revenues-pension"
                  : "revenues-additional";

          return {
            ...navigation,
            branchSlug: newBranchSlug,
            categorySlug: "revenues",
            categoryIteration: 1,
          };
        }
      } else {
        throw new Error("Unknown job type");
      }
    },
    handleBack: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "customer-info-common",
        };
      }
      const customerMaritalStatus = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.CustomerMaritalStatus &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      switch (customerMaritalStatus) {
        case "married":
          return {
            ...navigation,
            branchSlug: "customer-info-married",
          };
        case "single":
        case "pacs":
        case MaritalStatus.cohabitation:
          return {
            ...navigation,
            branchSlug: "customer-info-marital-status",
          };
      }
      throw new Error("Unknown marital status");
    },
  },
  {
    slug: "customer-info-job-employee",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.CustomerJobEmployeeType,
      )!,
    ],
    handleNext: (answers, navigation) => {
      const currentCustomerJobEmployeeType = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.CustomerJobEmployeeType &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (currentCustomerJobEmployeeType === CustomerEmployeeType.CDD) {
        if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
          return {
            ...navigation,
            branchSlug: "customer-info-common",
            categoryIteration: 2,
          };
        } else {
          // TODO factorize this logic
          const mainCustomerJobType = getCustomerJobType(1, answers);
          const newBranchSlug: BranchSlug =
            mainCustomerJobType === JobType.employee
              ? "revenues-employee"
              : mainCustomerJobType === JobType.self_employed
                ? "revenues-last-year"
                : mainCustomerJobType === JobType.unemployed
                  ? "revenues-additional"
                  : "revenues-pension";

          return {
            ...navigation,
            branchSlug: newBranchSlug,
            categorySlug: "revenues",
            categoryIteration: 1,
          };
        }
      }
      if (currentCustomerJobEmployeeType === CustomerEmployeeType.CDI) {
        return {
          ...navigation,
          branchSlug: "customer-info-job-permanent",
        };
      }

      throw new Error("Unknown job employee type");
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-job",
      };
    },
  },
  {
    slug: "customer-info-job-other",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.CustomerHasEnoughAnnualIncome,
      )!,
    ],
    handleNext: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        return {
          ...navigation,
          branchSlug: "customer-info-common",
          categoryIteration: 2,
        };
      } else {
        if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
          return {
            ...navigation,
            branchSlug: "customer-info-common",
            categoryIteration: 2,
          };
        } else {
          // TODO factorize this logic
          const mainCustomerJobType = getCustomerJobType(1, answers);
          const newBranchSlug: BranchSlug =
            mainCustomerJobType === JobType.employee
              ? "revenues-employee"
              : mainCustomerJobType === JobType.self_employed
                ? "revenues-last-year"
                : mainCustomerJobType === JobType.unemployed
                  ? "revenues-additional"
                  : "revenues-pension";

          return {
            ...navigation,
            branchSlug: newBranchSlug,
            categorySlug: "revenues",
            categoryIteration: 1,
          };
        }
      }
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-job",
      };
    },
  },
  {
    slug: "customer-info-job-permanent",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.CustomerJobTrial)!,
    ],
    handleNext: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        return {
          ...navigation,
          branchSlug: "customer-info-common",
          categoryIteration: 2,
        };
      } else {
        if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
          return {
            ...navigation,
            branchSlug: "customer-info-common",
            categoryIteration: 2,
          };
        } else {
          // TODO factorize this logic
          const mainCustomerJobType = getCustomerJobType(1, answers);
          const newBranchSlug: BranchSlug =
            mainCustomerJobType === JobType.employee
              ? "revenues-employee"
              : mainCustomerJobType === JobType.self_employed
                ? "revenues-last-year"
                : mainCustomerJobType === JobType.unemployed
                  ? "revenues-additional"
                  : "revenues-pension";

          return {
            ...navigation,
            branchSlug: newBranchSlug,
            categorySlug: "revenues",
            categoryIteration: 1,
          };
        }
      }
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "customer-info-job-employee",
      };
    },
  },
  {
    slug: "revenues-employee",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AnnualIncome)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "revenues-additional",
      };
    },
    handleBack: (answers, navigation) => {
      if (navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "revenues-additional",
          categoryIteration: 1,
        };
      } else if (!hasCoCustomer(answers)) {
        const customerJobEmployeeType = getCustomerJobEmployeeType(1, answers);

        return {
          ...navigation,
          branchSlug:
            customerJobEmployeeType === CustomerEmployeeType.CDD
              ? "customer-info-job-employee"
              : "customer-info-job-permanent",
        };
      } else {
        // TODO factorize this logic
        let newBranchSlug: BranchSlug;
        const coCustomerJobType = getCustomerJobType(2, answers);

        if (coCustomerJobType === JobType.employee) {
          const coCustomerJobEmployeeType = getCustomerJobEmployeeType(
            2,
            answers,
          );

          if (coCustomerJobEmployeeType === CustomerEmployeeType.CDD) {
            newBranchSlug = "customer-info-job-employee";
          } else {
            newBranchSlug = "customer-info-job-permanent";
          }
        } else {
          newBranchSlug =
            coCustomerJobType === JobType.self_employed
              ? "customer-info-job-other"
              : "customer-info-job";
        }

        return {
          ...navigation,
          branchSlug: newBranchSlug,
          categorySlug: "situation",
          categoryIteration: 2,
        };
      }
    },
  },
  {
    slug: "revenues-last-year",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.LastYearAnnualIncome,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "revenues-additional",
      };
    },
    handleBack: (answers, navigation) => {
      if (navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "revenues-additional",
          categoryIteration: 1,
        };
      } else if (!hasCoCustomer(answers)) {
        return {
          ...navigation,
          branchSlug: "customer-info-job-other",
          categorySlug: "situation",
          categoryIteration: 1,
        };
      } else {
        // TODO factorize this logic
        let newBranchSlug: BranchSlug;
        const coCustomerJobType = getCustomerJobType(2, answers);
        if (coCustomerJobType === JobType.employee) {
          const coCustomerJobEmployeeType = getCustomerJobEmployeeType(
            2,
            answers,
          );

          if (coCustomerJobEmployeeType === CustomerEmployeeType.CDD) {
            newBranchSlug = "customer-info-job-employee";
          } else {
            newBranchSlug = "customer-info-job-permanent";
          }
        } else {
          newBranchSlug =
            coCustomerJobType === JobType.self_employed
              ? "customer-info-job-other"
              : "customer-info-job";
        }

        return {
          ...navigation,
          branchSlug: newBranchSlug,
          categorySlug: "revenues",
          categoryIteration: 2,
        };
      }
    },
  },
  {
    slug: "revenues-pension",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AnnualPensionIncome)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "revenues-additional",
      };
    },
    handleBack: (answers, navigation) => {
      if (navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "revenues-additional",
          categoryIteration: 1,
        };
      } else if (!hasCoCustomer(answers)) {
        return {
          ...navigation,
          branchSlug: "customer-info-job",
          categorySlug: "situation",
          categoryIteration: 1,
        };
      } else {
        // TODO factorize this logic
        let newBranchSlug: BranchSlug;
        const coCustomerJobType = getCustomerJobType(2, answers);

        if (coCustomerJobType === JobType.employee) {
          const coCustomerJobEmployeeType = getCustomerJobEmployeeType(
            2,
            answers,
          );
          if (coCustomerJobEmployeeType === CustomerEmployeeType.CDD) {
            newBranchSlug = "customer-info-job-employee";
          } else {
            newBranchSlug = "customer-info-job-permanent";
          }
        } else {
          newBranchSlug =
            coCustomerJobType === JobType.self_employed
              ? "customer-info-job-other"
              : "customer-info-job";
        }

        return {
          ...navigation,
          branchSlug: newBranchSlug,
          categorySlug: "situation",
          categoryIteration: 2,
        };
      }
    },
  },
  {
    slug: "revenues-additional",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.AdditionalIncomeInfo,
      )!,
    ],
    handleNext: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        // TODO factorize this logic
        const coCustomerJobType = getCustomerJobType(2, answers);
        const newBranchSlug: BranchSlug =
          coCustomerJobType === JobType.employee
            ? "revenues-employee"
            : coCustomerJobType === JobType.self_employed
              ? "revenues-last-year"
              : coCustomerJobType === JobType.unemployed
                ? "revenues-additional"
                : "revenues-pension";

        return {
          ...navigation,
          branchSlug: newBranchSlug,
          categoryIteration: 2,
        };
      } else {
        return {
          ...navigation,
          branchSlug: "charges-initial",
          categorySlug: "charges",
          categoryIteration: 1,
        };
      }
    },
    handleBack: (answers, navigation) => {
      const isRevenuesEmployeeFilled = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.AnnualIncome &&
          a.categoryIteration === navigation.categoryIteration,
      );

      if (!hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        const customerJobType = getCustomerJobType(1, answers);

        if (customerJobType === JobType.unemployed) {
          return {
            ...navigation,
            branchSlug: "customer-info-job",
            categoryIteration: 1,
          };
        }
      }

      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        const coCustomerJobType = getCustomerJobType(2, answers);
        const customerJobType = getCustomerJobType(1, answers);
        const coCustomerJobEmployeeType = getCustomerJobEmployeeType(
          2,
          answers,
        );
        const branchSlug: BranchSlug =
          coCustomerJobType === JobType.employee
            ? coCustomerJobEmployeeType === CustomerEmployeeType.CDD
              ? "customer-info-job-employee"
              : "customer-info-job-permanent"
            : coCustomerJobType === JobType.self_employed
              ? "customer-info-job-other"
              : "customer-info-job";

        if (customerJobType === JobType.unemployed) {
          return {
            ...navigation,
            branchSlug: branchSlug,
            categoryIteration: 2,
            categorySlug: "situation",
          };
        }
      }

      if (navigation.categoryIteration === 2) {
        const coCustomerJobType = getCustomerJobType(2, answers);

        if (coCustomerJobType === JobType.unemployed) {
          return {
            ...navigation,
            branchSlug: "revenues-additional",
            categoryIteration: 1,
          };
        }
      }

      if (isRevenuesEmployeeFilled) {
        return {
          ...navigation,
          branchSlug: "revenues-employee",
        };
      } else {
        const isRevenuesLastYearFilled = answers.find(
          (a) =>
            a.questionSlug === QuestionSimpleSlug.LastYearAnnualIncome &&
            a.categoryIteration === navigation.categoryIteration,
        );
        if (isRevenuesLastYearFilled) {
          return {
            ...navigation,
            branchSlug: "revenues-last-year",
          };
        } else {
          const isRevenuesPensionFilled = answers.find(
            (a) =>
              a.questionSlug === QuestionSimpleSlug.AnnualPensionIncome &&
              a.categoryIteration === navigation.categoryIteration,
          );

          if (isRevenuesPensionFilled) {
            return {
              ...navigation,
              branchSlug: "revenues-pension",
            };
          } else {
            throw new Error("Cannot determine previous branch");
          }
        }
      }
    },
  },
  {
    slug: "charges-initial",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AlimonyAnnualAmount)!,
    ],
    handleNext: (answers, navigation) => {
      const hasAlreadyFilledMainHomeInfo = isMainHomeInfoFilled(answers);
      const isMainCustomer = navigation.categoryIteration === 1;
      if (hasAlreadyFilledMainHomeInfo || !isMainCustomer) {
        return {
          ...navigation,
          branchSlug: "charges-additional-loans-info",
        };
      } else {
        return {
          ...navigation,
          branchSlug: "charges-housing-status",
        };
      }
    },
    handleBack: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "charges-additional-loans-info",
          categoryIteration: 1,
        };
      }
      return {
        ...navigation,
        branchSlug: "revenues-additional",
        categorySlug: "revenues",
        categoryIteration: hasCoCustomer(answers) ? 2 : 1,
      };
    },
  },
  {
    slug: "charges-housing-status",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.HousingStatus)!,
    ],
    handleNext: (answers, navigation) => {
      const housingStatus = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.HousingStatus &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;
      switch (housingStatus) {
        case HousingStatus.landlord: {
          return {
            ...navigation,
            branchSlug: "charges-housing-loan-info",
          };
        }
        case HousingStatus.tenant: {
          return {
            ...navigation,
            branchSlug: "charges-housing-rent-info",
          };
        }
      }
      throw new Error("Unknown housing status");
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-initial",
      };
    },
  },
  {
    slug: "charges-housing-rent-info",
    questions: [
      questions.find(
        (q) => q.slug === QuestionSimpleSlug.HousingRentMonthlyAmount,
      )!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-additional-loans-info",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-housing-status",
      };
    },
  },
  {
    slug: "charges-housing-loan-info",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.HousingLoans)!,
    ],
    handleNext: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-additional-loans-info",
      };
    },
    handleBack: (answers, navigation) => {
      return {
        ...navigation,
        branchSlug: "charges-housing-status",
      };
    },
  },
  {
    slug: "charges-additional-loans-info",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.AdditionalLoans)!,
    ],
    handleNext: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        return {
          ...navigation,
          branchSlug: "charges-additional-loans-info",
          categoryIteration: 2,
        };
      }
      return {
        ...navigation,
        branchSlug: "patrimony-investments-info",
        categorySlug: "patrimony",
        categoryIteration: 1,
      };
    },
    handleBack: (answers, navigation) => {
      const housingStatus = answers.find(
        (a) =>
          a.questionSlug === QuestionSimpleSlug.HousingStatus &&
          a.categoryIteration === navigation.categoryIteration,
      )?.value;

      if (hasCoCustomer(answers) && navigation.categoryIteration === 2) {
        return {
          ...navigation,
          branchSlug: "charges-additional-loans-info",
          categoryIteration: 1,
        };
      }

      if (housingStatus === HousingStatus.landlord) {
        return {
          ...navigation,
          branchSlug: "charges-housing-loan-info",
        };
      } else if (housingStatus === HousingStatus.tenant) {
        return {
          ...navigation,
          branchSlug: "charges-housing-rent-info",
        };
      } else {
        return {
          ...navigation,
          branchSlug: "charges-initial",
        };
      }
    },
  },
  {
    slug: "patrimony-investments-info",
    questions: [
      questions.find((q) => q.slug === QuestionSimpleSlug.InvestmentsInfo)!,
    ],
    handleNext: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 1) {
        return {
          ...navigation,
          categoryIteration: 2,
        };
      } else {
        return {
          ...navigation,
          categorySlug: "eligibility",
          exitPage: "personal",
        };
      }
    },
    handleBack: (answers, navigation) => {
      if (hasCoCustomer(answers) && navigation.categoryIteration === 2) {
        return {
          ...navigation,
          categoryIteration: 1,
        };
      }
      return {
        ...navigation,
        branchSlug: "charges-additional-loans-info",
        categorySlug: "charges",
        categoryIteration: hasCoCustomer(answers) ? 2 : 1,
      };
    },
  },
];
