import {
  CasePurpose,
  CustomerAdditionalChargeType,
  CustomerAdditionalIncomeType,
  MaritalStatus,
  MarriageType,
} from "@prisma/client";
import { type Answer } from "./interfaces";

export type CasePurposeTranslation =
  | "Achat immobilier"
  | "Faire des travaux"
  | "Réduire mon endettement"
  | "Investir"
  | "Disposer de liquidités"
  | "Apport compte courant associé"
  | "Financer une acquisition"
  | "Financer une vente à soi-même"
  | "Rembourser un compte courant";

export const casePurposeMapping: Record<CasePurpose, CasePurposeTranslation> = {
  [CasePurpose.purchase_new_asset]: "Achat immobilier",
  [CasePurpose.renovate]: "Faire des travaux",
  [CasePurpose.debt_reduction]: "Réduire mon endettement",
  [CasePurpose.investment]: "Investir",
  [CasePurpose.liquidity]: "Disposer de liquidités",
  [CasePurpose.associated_current_account_contribution]:
    "Apport compte courant associé",
  [CasePurpose.finance_acquisition]: "Financer une acquisition",
  [CasePurpose.finance_self_sale]: "Financer une vente à soi-même",
  [CasePurpose.repay_current_account]: "Rembourser un compte courant",
};

export enum OnboardingMaritalStatus {
  Married = "Marié",
  Single = "Célibataire",
  Pacs = "Pacsé",
  Cohabitation = "Concubinage",
}

export const onboardingMaritalStatusMapping: Record<
  MaritalStatus,
  OnboardingMaritalStatus
> = {
  [MaritalStatus.married]: OnboardingMaritalStatus.Married,
  [MaritalStatus.single]: OnboardingMaritalStatus.Single,
  [MaritalStatus.cohabitation]: OnboardingMaritalStatus.Cohabitation,
  [MaritalStatus.pacs]: OnboardingMaritalStatus.Pacs,
};

export enum OnboardingAdditionalChargeType {
  Alimony = "Pension alimentaire",
  Other = "Autre",
}

export const onboardingAdditionalChargeTypeMapping: Record<
  CustomerAdditionalChargeType,
  OnboardingAdditionalChargeType
> = {
  [CustomerAdditionalChargeType.alimony]:
    OnboardingAdditionalChargeType.Alimony,
  [CustomerAdditionalChargeType.other]: OnboardingAdditionalChargeType.Other,
};

export enum OnboardingMarriageType {
  LegalCommunity = "Communauté légale",
  Separation = "Séparation de biens",
  UniversalCommunity = "Communauté de biens universelle",
  Contract = "Contrat de mariage",
  Other = "Autre",
}

export const onboardingMarriageTypeMapping: Record<
  MarriageType,
  OnboardingMarriageType
> = {
  [MarriageType.communaute_legale]: OnboardingMarriageType.LegalCommunity,
  [MarriageType.separation_de_biens]: OnboardingMarriageType.Separation,
  [MarriageType.communaute_de_biens_universelle]:
    OnboardingMarriageType.UniversalCommunity,
  [MarriageType.participation_aux_acquets]: OnboardingMarriageType.Contract,
  [MarriageType.autre]: OnboardingMarriageType.Other,
};

export enum OnboardingAdditionalIncomeType {
  Revenu_foncier = "Revenu foncier",
  Produit_de_placement = "Produit de placement",
  Pension = "Pension",
  Variable = "Variable",
  Allocation_CAF = "Allocation CAF",
}
export const onboardingAdditionalIncomeType: Record<
  CustomerAdditionalIncomeType,
  OnboardingAdditionalIncomeType
> = {
  [CustomerAdditionalIncomeType.other_rent]:
    OnboardingAdditionalIncomeType.Revenu_foncier,
  [CustomerAdditionalIncomeType.investment]:
    OnboardingAdditionalIncomeType.Produit_de_placement,
  [CustomerAdditionalIncomeType.pension]:
    OnboardingAdditionalIncomeType.Pension,
  [CustomerAdditionalIncomeType.variable]:
    OnboardingAdditionalIncomeType.Variable,
  [CustomerAdditionalIncomeType.caf]:
    OnboardingAdditionalIncomeType.Allocation_CAF,
};

export type CategorySlug =
  | "case"
  | "real-estate-patrimony"
  | "situation"
  | "revenues"
  | "charges"
  | "patrimony"
  | "eligibility"
  | "company-info"
  | "company-info-extra";

export type Category = {
  slug: CategorySlug;
  name: string;
  iterations?: (answers: Answer[]) => number;
  shouldDisplayIterationInfo?: "co-customer" | "asset-count";
};
